import { Navigate, Routes, Route } from 'react-router-dom';
import AboutView from './components/AboutView';
import HomeView from './components/HomeView';
import NavBar from './components/NavBar';

export default function App() {
  return (
    <div className="App">
      <header>
        <Routes>
          <Route path="*" element={<NavBar />} />
          <Route path="/" element={<Navigate to="/home" />} />
        </Routes>
      </header>
      <main>
        <Routes>
          <Route path="/home" element={<HomeView />} />
          <Route path="/about" element={<AboutView />}></Route>
        </Routes>
      </main>
    </div>
  );
}
