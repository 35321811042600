import styles from './TimelineElement.module.scss'

export default function TimelineElement({ title, date, image, altText, description }) {
    return (
        <div className={styles.timeline}>
            <div className={styles.headingWrapper}>
                <h1>{title}</h1>
                <p>{date}</p>
            </div>
            <div className={styles.timelineImageWrapper}>
                <div className={styles.shadow}>
                    <div className={styles.imageFront}>
                        <img src={image} className={styles.timelineImage} alt={altText} />
                    </div>
                    <div className={styles.imageBack}>
                        <p>{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}