import { useState, useEffect, useRef } from 'react';
import { Link, Route, Routes, } from 'react-router-dom';
import styles from './NavBar.module.scss'

export default function NavBar() {
    const [showBurger, setShowBurger] = useState(false);
    const [showNavbar, setShowNavbar] = useState(true);
    const prevScrollY = useRef(0)

    const resetBurger = () => {
        setShowBurger(current => !current)
    }

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > prevScrollY.current) {
            setShowNavbar(false);
            setShowBurger(false);
        } else {
            setShowNavbar(true);
            setShowBurger(false);
        }

        prevScrollY.current = currentScrollY;
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Routes>
            <Route
                path="*"
                element={
                    <nav className={`${styles.navbar} ${showNavbar ? styles.visibleNavBar : styles.hiddenNavBar}`}>
                        <div className={styles.logo}></div>
                        <input type="checkbox" id="checkbox_toggle" checked={showBurger} onChange={resetBurger} />
                        <label htmlFor="checkbox_toggle" className={styles.hamburger}>&#9776;</label>
                        <div className={styles.menu}>
                            <Link to="/home"><li onClick={resetBurger}>Home</li></Link>
                            <Link to="/about"><li onClick={resetBurger}>About</li></Link>
                            {/* Move to about page? */}
                            <a href="CV_Denis.pdf" download><li onClick={resetBurger}>CV</li></a>
                            {/* <Link to="/projects"><li onClick={resetBurger}>Projects</li></Link> */}
                            {/* <Link to="/terminal"><li onClick={resetBurger}>Terminal</li></Link> */}
                        </div>
                    </nav>
                }
            />
        </Routes>
    )
}