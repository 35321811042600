import styles from './AboutView.module.scss'
import TimelineElement from './TimelineElement'

export default function AboutView() {
    const ABOUT_ME = "I have recently graduated with a bachelor's degree in Computer Science & Software Engineering from Delft University of Technology. "
        + "Currently I am taking a gap year, during which I am working full-time as a Software Engineer at Cloudflight. "
        + "Within this gap year, I will focus on developing my skills in System Design and DevOps, and gaining valuable hands-on experience by working on a large enterprise project.\n\n"
        // + "I am eager to learn more and develop in the spheres of machine learning, AI and data analysis, manipulation and processing.\n\n"
        // TODO: add something about stuff I know (programming languages, etc.)
        + "Some of my hobbies consist of playing various card and board games, such as poker and chess. "
        + "Also, on the weekends, I enjoy spending time with friends either doing sports or just having a chat over some drinks. "
        + "Finally, depending on the season I like cycling, hiking, skiing, and most importantly travelling and exploring new places."
    const SHM = "This was one of the most 'math-heavy' periods of my life, peaking at roughly 14 hours of mathematics lessons per week, including extracurricular activities. "
        + "My program specialized in informatics, although I was only introduced to simple algorithms and data structures in C++. "
        + "Outside of school I attended numerous mathematics competitions within Bulgaria with lots of good results and a couple of top 3 finishes. "
        + "Finally, I graduated with a diploma grade of 5.79 out of 6 after an excellent performance on my state exams at the end of my studies."
    const TUD = "My three-year journey at TU Delft has recently come to an end, but it is something that I will undoubtedly remember for a long time. "
        + "Even though I spent more than a third of the time watching lectures online due to Covid, I still managed to acquire a lot of knowledge and valuable experience. "
        + "In the end, I graduated with distinction ('cum laude') after achieving a grade of 8.5 on my Bachelor's thesis and an overall GPA of 8.06 out of 10.\n\n"
        + "Selected Coursework: Algorithms & Data Structures, Algorithm Design, Algorithms for NP-Hard Problems, Machine Learning, Computational Intelligence, Collaborative Artificial Intelligence"
    const KB = "During this ten-week collaborative project between the TU Delft and the KB National Library, I had the opportunity to gain experience in various aspects of software development. "
        + "Me and my team managed to design and implement from scratch an open-source web application to make e-books more accessible to visually impaired people. "
        + "We achieved this by first collecting user requirements from our client and systematically incorporating them using the Agile methodology. "
        + "Within this framework, I was able to step in as a Scrum Master for my team and take charge of weekly meetings, estimation sessions, etc.\n\n"
        + "Utilized Technologies: Python, Django, JavaScript, React, Docker, MongoDB"
    const CLF = "For the past 1.5 years I have been working on an outsourced project for a prominent European transportation and logistics company. "
        + "In my first 12 months at Cloudflight I was engaged in the refactoring of a significant monolith into multiple microservices. "
        + "Currently, I am overseeing one of the project's subdomains, responsible for email notifications. This includes participating in client meeting, story preparation, and solving complex problems. "
        + "Furthermore, I am actively involved in various DevOps tasks, such as production deployments or fixing indicents.\n\n"
        + "Utilized Technologies: Java, Spring Boot, Kafka, Microsoft SQL Server, Jenkins, Docker, Kubernetes, Helm, Grafana"

    return (
        <div className={styles.readmeWrapper}>
            <div></div>
            <div className={styles.readme}>
                <div className={styles.titleWrapper}>
                    <div className={styles.readmeTitle}><b>&#9776; &nbsp; README.md</b></div>
                    <div className={styles.readmeEdit}></div>
                </div>
                <div className={styles.aboutWrapper}>
                    <h1>About Me</h1>
                    <p className={styles.description}>{ABOUT_ME}</p>
                    <h2>Education</h2>
                    <TimelineElement
                        title="🎓Sofia High School of Mathematics"
                        date="2012 - 2020"
                        image="/media/about/SHM.webp"
                        altText="Sofia Highschool of Mathematics"
                        description={SHM}
                    />
                    <TimelineElement
                        title="🎓Delft University of Technology"
                        date="2020 - 2023"
                        image="/media/about/TUD.webp"
                        altText="Delft University of Technology"
                        description={TUD}
                    />
                    <h3>Experience</h3>
                    <TimelineElement
                        title="📚Royal Library of the Netherlands"
                        date="4/2022 - 6/2022"
                        image="/media/about/KB.webp"
                        altText="Royal Library of the Netherlands"
                        description={KB}
                    />
                    <TimelineElement
                        title="💼Cloudflight"
                        date="7/2022 - now"
                        image="/media/about/CLF.webp"
                        altText="Cloudflight Logo"
                        description={CLF}
                    />
                </div>
            </div>
            <div></div>
        </div>
    )
}